.wrap {
  height: 100%;
  justify-content: space-between;
}
.flex {
  display: flex;
  flex-direction: column;
}
.form {
  /* width: 100%; */
  border: none;
}
.fieldWrap {
  position: relative;
  display: flex;
  justify-content: center;
}
.fieldWrapWithAdditional {
  flex-direction: column;
}
.label-text.required-field::after {
  content: "*";
  color: red;
  margin-left: 5px;
}
.required-field.label-text {
  padding-right: 10px;
}
.label {
  width: 100%;
}
.label-text {
  position: absolute;
  font-size: 14px;
  top: -12px;
  left: 15px;
  padding: 2px 15px;
  z-index: 1;
  background: #fff;
  color: grey;
}
.field {
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid grey;
  margin-bottom: 40px;
}
.field:focus {
  outline: 1px solid grey;
}
.validationFieldWrap {
  margin-bottom: 30px;
}
.field::placeholder {
  color: #80808069;
}
.checkBox-position {
  margin-right: 3%;
}
.checkBox-label {
  display: inline-flex;
  align-items: center;
}
.checkBox + span{
  font-size: 16px;
}
.privacy {
  position: static;
  padding-left: 0;
}
.footer {
  display: flex;
  justify-content: space-between;
  margin: 60px auto 0;
  width: 100%;
}
.submit {
  box-shadow: 0 0 5px grey;
  background: #ff4dd1;
  border: none;
}
.footer_error {
  display: block;
  height: 100%;
  padding: 7px 10px 0;
  color: rgb(253, 50, 50);
}
.disabled {
  background: #f9a5e3;
  color: #252934;
  border-color: #f9a5e3;
  cursor: default;
}
.disabled:hover {
  box-shadow: 0 0 5px grey;
}
.error {
  border-color: rgb(253, 50, 50);
  box-shadow: 0 0 2px rgb(253 50 50);
}
.error-message {
  position: absolute;
  bottom: 18px;
  display: block;
  margin-top: 5px;
  border: none;
  box-shadow: none;
  color: rgb(253, 50, 50);
  font-size: .7em;
}
.additional_block {

}