.subMenuWrap {
  position: absolute;
  top: 34px;
  left: 0;
  background-color: #181d23;
  
  /* margin-top: -2px; */
  /* z-index: -1; */
}
.menu * {
  color: #ffffff !important;
  font-size: 1rem !important;
}
.menu {
  list-style: none;
  padding: 15px 3rem 15px 15px;
  padding-top: 20px;
  /* margin-top: -10px; */
  /* z-index: -1; */
}

.menu li:not(:first-child) {
  margin-top: 10px;
}
.logoutButton {
  border: none;
  padding: 0;
}
.logoutButton:hover {
  box-shadow: none;
}