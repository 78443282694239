.wrap {
		clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
		padding-top: 8rem !important;
}
.button {
	background: #f300b4 !important;
	border-color: #f300b4 !important;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 20px;
}

@media only screen and (max-width: 949px) {
	.wrap {
		clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
	}
}
