.button {
	padding: 15px 20px;
	background: transparent;
	border: 1px solid #fafafa;
	color: #fafafa;
	font-size: 14px;
	cursor: pointer;
	border-radius: 4px;
	transition: .3s;
}

.button:hover {
	box-shadow: 0 0 15px grey;
}