.wrap {
  clip-path: none;
}
.playerCards {
  display: flex;
}
.card {
  max-width: 18vw;
  margin: 0 0 0 -3vw !important;
  box-shadow: none !important;
}

.card:first-child {
  margin-left: 0 !important;
}

.button {
  background: #f300b4;
  margin: 60px auto;
  padding: 15px 56px;
  border: none;
};

/* @media only screen and (max-width: 949px) {
  .playerCards {
    flex-wrap: wrap;
  }
  .card {
    margin: 0;
    width: 18vw;
  }
} */