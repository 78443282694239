.wrap {
  max-width: 70vw;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  margin: 0 auto;
}

.auth-wrap {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 10px grey;
  border-radius: 4px;
  min-height: 460px;
}
.toggleWrapPanel {
  position: absolute;
  top: 0;
  right: 0;
  transform: none;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(3,74,247,1) 0%, rgba(22,203,250,1) 80%);
  transition-property: left, right;
  transition-duration: .5s;
  z-index: 2;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.toggleWrapPanel.right {
  right: 50%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.container {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.title-wrap {
  margin-bottom: 2rem;
  justify-content: space-around;
}
.button-wrap {
  position: relative;
  text-align: center;
  padding: 0;
  width: auto;
  font-size: 1.5rem;
  color: grey;
}
.button-wrap.active {
  color: black;
}
.button-wrap:hover::after {
  background: #f300b4;
}
.button-wrap:hover {
  box-shadow: none;
  color: black;
}
.button-wrap::after {
  content: "";
  position: absolute;
  bottom: -.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background: #f9a5e3;
  transition: background .4s;
}
.button-wrap.active::after {
  background: #f300b4;
}
.input {
  width: 100%;
}