*,
*::before,
*::after,
:root {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@import "https://fonts.googleapis.com/css?family=Overlock:400,400i,700|Oleo+Script";

html,
body {
	height: 100%;
}

body {
	color: #252934;
	background: #fafafa;
	font-size: 62.5%;
	font-family: "Overlock", Arial, Helvetica, sans-serif;
	overflow-x: hidden;
}

a,
a:visited {
	color: #252934;
	font-size: 1.4rem;
	text-decoration: none;
	transition: 200ms;
}

a:hover,
a:active {
	color: #f300b4;
}
