@import url(https://fonts.googleapis.com/css?family=Overlock:400,400i,700|Oleo+Script);
*,
*::before,
*::after,
:root {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	color: #252934;
	background: #fafafa;
	font-size: 62.5%;
	font-family: "Overlock", Arial, Helvetica, sans-serif;
	overflow-x: hidden;
}

a,
a:visited {
	color: #252934;
	font-size: 1.4rem;
	text-decoration: none;
	transition: 200ms;
}

a:hover,
a:active {
	color: #f300b4;
}

.style_wrap__2I4Oh {
		-webkit-clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
		        clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
		padding-top: 8rem !important;
}
.style_button__iaGK0 {
	background: #f300b4 !important;
	border-color: #f300b4 !important;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 20px;
}

@media only screen and (max-width: 949px) {
	.style_wrap__2I4Oh {
		-webkit-clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
		        clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
	}
}

.style_root__3EDU_ {
	background: #202736;
	background: linear-gradient(to bottom, #181d23 0%, #202736 80%);
	background-attachment: fixed;
	background-size: cover;
	position: relative;
	min-height: 100vh;
	margin: 0 auto;
	z-index: 1;
}

.style_root__3EDU_:before {
	content: "";
	position: fixed;
	background: url(/static/media/Stars.8a0c1e35.svg);
	background-attachment: fixed;
	width: 100%;
	min-height: 100vh;
	z-index: -1;
	opacity: 0;
	animation: style_stars-move-in__3cgo4 1000ms 300ms forwards;
}

@keyframes style_stars-move-in__3cgo4 {
	from {
		background-position-y: -100px;
	}
	to {
		opacity: 1;
		background-position-y: 0;
	}
}

.style_forest__2l_b9 {
	position: absolute;
	bottom: -300px;
	left: 0;
	background: url(/static/media/Trees.6947010d.svg) bottom left repeat-x;

	background-size: cover;
	width: 100%;
	height: 80%;
	opacity: 0;
	animation: style_forest-move-in__qZA3m 1000ms 500ms forwards;
	border-bottom: 300px solid #181d23;
}

@keyframes style_forest-move-in__qZA3m {
	from {
		background-position-y: 150%;
	}
	to {
		opacity: 1;
		background-position-y: 100%;
	}
}

.style_silhouette__13n9s {
	position: absolute;
	bottom: 0;
	left: 0;
	background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Silhouette.svg?sanitize=true)
		bottom left no-repeat;
	width: 50%;
	height: 50%;
	opacity: 0;
	animation: style_silhouette-move-in__3Avbb 1000ms 800ms forwards;
}

@keyframes style_silhouette-move-in__3Avbb {
	from {
		background-position-x: 0;
	}
	to {
		opacity: 1;
		background-position-x: 50%;
	}
}

.style_moon__2fHAp {
	top: 0;
	right: 0;
	position: fixed;
	background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Moon.svg?sanitize=true)
		right 150% no-repeat;
	background-size: 30% 30%;
	background-attachment: fixed;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	animation: style_moon-move-in__j_efo 1.2s 1s forwards;
}

@keyframes style_moon-move-in__j_efo {
	from {
		opacity: 0;
		background-position: right 150%;
	}
	to {
		opacity: 1;
		background-position: top right;
	}
}

.style_container__3TbXE {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	right: 0;
	top: 50%;
	left: 55%;
	opacity: 0;
	transform: translate(-50%, -50%);
	animation: style_text-fade-in__32u_A 1000ms 800ms forwards;
}

@keyframes style_text-fade-in__32u_A {
	from {
		right: 0;
	}
	to {
		opacity: 1;
		right: 25%;
	}
}

.style_container__3TbXE h1 {
	font-size: 65px;
	font-weight: normal;
	font-style: italic;
	color: #fafafa;
	line-height: 65px;
}

.style_container__3TbXE p {
	font-size: 28px;
	font-weight: normal;
	color: #fafafa;
	line-height: 32px;
	margin-bottom: 24px;
}

@media only screen and (max-width: 649px) {
	.style_container__3TbXE {
		top: 35%;
		width: 80%;
		animation: style_text-fade-in__32u_A 1000ms 800ms forwards;
	}

	@keyframes style_text-fade-in__32u_A {
		from {
			right: 0;
		}
		to {
			opacity: 1;
			right: 50%;
		}
	}

	.style_silhouette__13n9s {
		width: 100%;
	}
}

.style_button__1H5Da {
	padding: 15px 20px;
	background: transparent;
	border: 1px solid #fafafa;
	color: #fafafa;
	font-size: 14px;
	cursor: pointer;
	border-radius: 4px;
	transition: .3s;
}

.style_button__1H5Da:hover {
	box-shadow: 0 0 15px grey;
}
.style_root__3iurA {
	height: 100%;
	min-height: 100vh;
	font-size: 1.4rem;
	position: relative;
	background: #fafafa;
	z-index: 5;
	background-attachment: fixed;
	padding-top: 3rem;
}

.style_root__3iurA article {
	max-width: 1400px;
	margin: auto;
	padding: 0 2rem;
}

.style_root__3iurA .style_title__3q_Ze {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.style_root__3iurA .style_title__3q_Ze h3 {
	font-size: 2.4rem;
}

.style_root__3iurA .style_separator__3Iwm3 {
	background: #f300b4;
	width: 150px;
	height: 2px;
	margin: 1rem 0;
	padding: 0;
}

.style_root__3iurA .style_subtitle__3TSsQ {
	font-size: 1.6rem;
	text-align: center;
	color: inherit;
	padding-bottom: 1.5rem;
}

.style_root__3iurA p {
	padding-bottom: 1.5rem;
	color: #555;
	line-height: 1.9rem;
}

.style_root__3iurA .style_desc__14pKG.style_full__3XGPX {
	/* grid-column-end: span 4; */
	margin-bottom: 2rem;
}

.style_root__3iurA .style_desc__14pKG {
	/* grid-column-end: span 2; */
	background: #ffffffaa;
	padding: 2rem;
	text-align: justify;
}

@media only screen and (max-width: 1149px) {
	.style_root__3iurA article {
		/* grid-template-columns: 1fr;
		grid-gap: 0; */
	}
	.style_root__3iurA .style_desc__14pKG.style_full__3XGPX {
		/* grid-column-end: -1; */
	}

	.style_root__3iurA .style_desc__14pKG {
		/* grid-column-end: -1; */
	}
}
@media only screen and (max-width: 949px) {
	.style_root__3iurA {
		/* clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%); */
		background-position: top left;
		background-size: cover;
	}
}
@media only screen and (max-width: 649px) {
	.style_root__3iurA .style_wrapper__NNZoX {
		padding: 10rem 2rem 8rem;
	}
}

.style_wrap__3seFR {
	-webkit-clip-path: none;
	        clip-path: none;
	/* padding-top: 3rem; */
	/* padding-bottom: 0; */
}

.style_flex__19QqC {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.style_buttonContainer__3-WT6 {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 40px;
}

.style_addPokemonButton__2vOKE {
	background-color: #f300b4;
	border-color: #f300b4;
}

.style_disabled__3Fl5I {
	background-color: #ffa7e9 !important;
	border-color: #ffa7e9 !important; 
	cursor: default;
}

.style_disabled__3Fl5I:hover {
	box-shadow: none;
}

.style_description__33YhD{
	display: block;
	text-align: center;
	margin-bottom: 20px;
}
.style_card__3I4Ms {
	transition: margin-top .6s !important;
}
.style_pokemonCard__3qyD7 {
	--fire: #FDDFDF;
	--grass: #DEFDE0;
	--electric: #FCF7DE;
	--water: #DEF3FD;
	--ground: #f4e7da;
	--rock: #d5d5d4;
	--fairy: #fceaff;
	--poison: #98d7a5;
	--bug: #f8d5a3;
	--dragon: #97b3e6;
	--psychic: #eaeda1;
	--flying: #F5F5F5;
	--fighting: #E6E0D4;
	--normal: #F5F5F5;

	width: inherit;
	height: inherit;
	position: relative;
	transform-style: preserve-3d;
	/* transition-property: margin-top; */
	/* transition-duration: .6s; */
	transform: rotateY(180deg);
}

.style_pokemonCard__3qyD7 {
	width: 268px;
	height: 388px;
	margin: 16px;
	perspective: 1000px;
	border-radius: 24px;
}

.style_pokemonCard__3qyD7.style_selected__3cL5h {
	margin-top: -10px;
	margin-bottom: 20px;
}

.style_pokemonCard__3qyD7.style_selected__3cL5h.style_minimize__2wsxo:not(:first-child) {
	margin-top: -6vw;
}
.style_pokemonCard__3qyD7.style_selected__3cL5h.style_minimize__2wsxo {
	margin-left: -20px;
	margin-bottom: 0;
	box-shadow: none !important;
}
.style_pokemonCard__3qyD7.style_minimize__2wsxo {
	animation: none !important;
	transform: rotateY(0deg);
}

.style_pokemonCard__3qyD7.style_active__Dz414 {
	animation: style_flipFront__3iz5y 1000ms 300ms forwards;
	/* transform: rotateY(180deg); */
}

@keyframes style_flipFront__3iz5y {
	from {
		transform: rotateY(180deg);
	}
	to {
		transform: rotateY(0deg);
	}
}

.style_cardFront__18SB_,
.style_cardBack__oR7yV {
	width: 100%;
	height: 100%;
	border-radius: 24px;
	background: var(--normal);
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.style_cardFront__18SB_ {
	transform: rotateY(0deg);
	z-index: 2;
}

.style_wrap__3AL4q {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.style_wrap__3AL4q.style_front__3yRz1 {
	z-index: 2;
	padding: 10px;
}

.style_wrap__3AL4q.style_front__3yRz1::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	border: 12px solid #fce756;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 24px;
}

.style_pokemonCard__3qyD7:active .style_wrap__3AL4q.style_front__3yRz1::after {
	border-color: grey;
}

.style_wrap__3AL4q.style_back__10VkB {
	z-index: -1;
	background-image: url(/static/media/card-back-side.3bc67f21.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.style_cardBack__oR7yV {
	transform: rotateY(180deg);
	z-index: 1;
}

.style_cardBack__oR7yV img {
	height: 100%;
	border-radius: 24px;
}

.style_pokemon__3-eDz {
	width: 100%;
	height: 100%;
	background-color: #eee;
	border-radius: 20px;
	box-shadow: 0 3px 15px rgba(100, 100, 100, 0.5);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.style_fire__2Pwac {
	background-color: var(--fire);
}
.style_grass__Z7mxD {
	background-color: var(--grass);
}
.style_electric__tegY5 {
	background-color: var(--electric);
}
.style_water__3cIJF {
	background-color: var(--water);
}
.style_ground__15Fi3 {
	background-color: var(--ground);
}
.style_rock__2GEa4 {
	background-color: var(--rock);
}
.style_fairy__1uXIj {
	background-color: var(--fairy);
}
.style_poison__12mgM {
	background-color: var(--poison);
}
.style_bug__3SaT5 {
	background-color: var(--bug);
}
.style_dragon__jj6wx {
	background-color: var(--dragon);
}
.style_psychic__3nWXX {
	background-color: var(--psychic);
}
.style_flying__jIr1T {
	background-color: var(--flying);
}
.style_fighting__1OsqK {
	background-color: var(--fighting);
}
.style_normal__12O3J {
	background-color: var(--normal);
}

.style_pokemon__3-eDz .style_imgContainer__1WqEO {
	background-color: rgba(255, 255, 255, 0.6);
	border-radius: 50%;
	width: 140px;
	height: 140px;
	text-align: center;
	margin-bottom: 20px;
}

.style_pokemon__3-eDz .style_imgContainer__1WqEO img {
	margin-top: 20px;
	max-width: 90%;
}

.style_pokemon__3-eDz .style_info__1QRqQ {
	margin-top: 20px;
}

.style_pokemon__3-eDz .style_number__19BLV {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	font-size: 0.8em;
	padding: 5px 10px;
}

.style_pokemon__3-eDz .style_name__yS48U {
	margin: 15px 0 7px;
	letter-spacing: 1px;
}

.style_values__3Hg1n {
	position: absolute;
	display: block;
	top: 20px;
	left: 20px;
	font-size: 26px;
	line-height: 26px;
	width: 78px;
	height: 78px;
}

.style_count__3jcm_ {
	position: absolute;
	width: 30px;
	height: 26px;
	text-align: center;
}

.style_count__3jcm_.style_top__yml6L {
	left: 50%;
	transform: translate(-50%, 0);
	top: 0;
}

.style_count__3jcm_.style_left__22Xwm {
	top: 26px;
	left: 0;
}

.style_count__3jcm_.style_right__M9YAC {
	top: 26px;
	right: 0;
}

.style_count__3jcm_.style_bottom__N0y7W {
	left: 50%;
	transform: translate(-50%, 0);
	bottom: 0;
}

.style_blue__3nAI5 {
	background-color: #8db6ff;
}
.style_red__1tdrn {
	background-color: #f97cd8;
}
.style_root__31q2h {
	background-image: url(/static/media/board.73af5dd5.png);
	background-size: cover;
	background-repeat: no-repeat;
	height: calc(100vw / 1.71);
	position: relative;
}
.style_board__242EF {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	position: absolute;
	width: 50.3vw;
	height: 50.3vw;
	top: 4.3vw;
	left: 25.1vw;
}
.style_boardPlate__sSFIh {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	cursor: pointer;
}
.style_boardPlate__sSFIh:hover {
	background: rgba(255,255,255, .1);
}
.style_boardPlate__sSFIh.style_disabled__1ZLHw:hover {
	background: none;
}
.style_card__2ic25 {
	max-height: 14.8vw;
	margin: 0 auto;
	margin-top: -6vw;
	width: 100%;
	position: static !important;
}
.style_card__2ic25:first-child {
	margin-top: 0;
}

.style_player__3g4dK {
	position: absolute;
	top: 4.3vw;
	width: 15vw;
	height: 50.3vw;
}
.style_player__3g4dK.style_active__2ApU9 {
	box-sizing: content-box;
	box-sizing: initial;
	border-left: 1px solid #fce756;
	border-right: 1px solid #fce756;
	padding: 15px;
	-webkit-backdrop-filter: blur( 4px );
	        backdrop-filter: blur( 4px );
	background-color: rgb(177,118,43, .4);
	border-radius: 24px;
}
.style_one__3RiGX {
	left: 3vw;
}
.style_two__1vnK- {
	right: 3vw;
}
.style_chooseCard__3jz0X {
	margin-left: -20px;
}
.style_finishButton__1QKFw {
	margin-top: 100px;
}

.style_result__aN8qC {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.style_arrow__3H5pB {
    position: absolute;
    top: 50%;
    left: 50%;
    translateY: -50%;
    margin: -60px 0 0 -60px;
    width: 0;
    height: 0;
    /* Triangle Borders */
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 100px solid yellow;
    animation: style_spin__Eya02 1s linear infinite;
}

.style_rightSide__2HbzP {
    animation: none;
    transform: rotateY(0deg);
}

.style_leftSide__29gwz {
    animation: none;
    transform: rotateY(180deg);
}

@keyframes style_spin__Eya02 {
    100% {
        transform: rotateY(360deg);
    }
}

.style_wrap__1Vxhj {
  -webkit-clip-path: none;
          clip-path: none;
}
.style_playerCards__pKDxy {
  display: flex;
}
.style_card__14Ohd {
  max-width: 18vw;
  margin: 0 0 0 -3vw !important;
  box-shadow: none !important;
}

.style_card__14Ohd:first-child {
  margin-left: 0 !important;
}

.style_button__3EE-R {
  background: #f300b4;
  margin: 60px auto;
  padding: 15px 56px;
  border: none;
};

/* @media only screen and (max-width: 949px) {
  .playerCards {
    flex-wrap: wrap;
  }
  .card {
    margin: 0;
    width: 18vw;
  }
} */
.style_button__uFNQP {
	background: grey;
}

.style_button__2cGdM {
	background: grey;
}

.style_wrap__3pzl6 {
  max-width: 70vw;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  margin: 0 auto;
}

.style_auth-wrap__PbESb {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 10px grey;
  border-radius: 4px;
  min-height: 460px;
}
.style_toggleWrapPanel__434ZS {
  position: absolute;
  top: 0;
  right: 0;
  transform: none;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(3,74,247,1) 0%, rgba(22,203,250,1) 80%);
  transition-property: left, right;
  transition-duration: .5s;
  z-index: 2;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.style_toggleWrapPanel__434ZS.style_right__1hmU2 {
  right: 50%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.style_container__2Az7e {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.style_title-wrap__3Mfam {
  margin-bottom: 2rem;
  justify-content: space-around;
}
.style_button-wrap__1tQ0G {
  position: relative;
  text-align: center;
  padding: 0;
  width: auto;
  font-size: 1.5rem;
  color: grey;
}
.style_button-wrap__1tQ0G.style_active__1zfWy {
  color: black;
}
.style_button-wrap__1tQ0G:hover::after {
  background: #f300b4;
}
.style_button-wrap__1tQ0G:hover {
  box-shadow: none;
  color: black;
}
.style_button-wrap__1tQ0G::after {
  content: "";
  position: absolute;
  bottom: -.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background: #f9a5e3;
  transition: background .4s;
}
.style_button-wrap__1tQ0G.style_active__1zfWy::after {
  background: #f300b4;
}
.style_input__1oUjD {
  width: 100%;
}
.style_wrap__2RgLC {
  height: 100%;
  justify-content: space-between;
}
.style_flex__qowAg {
  display: flex;
  flex-direction: column;
}
.style_form__17lpq {
  /* width: 100%; */
  border: none;
}
.style_fieldWrap__3IhDl {
  position: relative;
  display: flex;
  justify-content: center;
}
.style_fieldWrapWithAdditional__IBhp8 {
  flex-direction: column;
}
.style_label-text__1wlV4.style_required-field__mGrv_::after {
  content: "*";
  color: red;
  margin-left: 5px;
}
.style_required-field__mGrv_.style_label-text__1wlV4 {
  padding-right: 10px;
}
.style_label__2Tgvq {
  width: 100%;
}
.style_label-text__1wlV4 {
  position: absolute;
  font-size: 14px;
  top: -12px;
  left: 15px;
  padding: 2px 15px;
  z-index: 1;
  background: #fff;
  color: grey;
}
.style_field__1n7Ee {
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid grey;
  margin-bottom: 40px;
}
.style_field__1n7Ee:focus {
  outline: 1px solid grey;
}
.style_validationFieldWrap__k_tkD {
  margin-bottom: 30px;
}
.style_field__1n7Ee::-webkit-input-placeholder {
  color: #80808069;
}
.style_field__1n7Ee:-ms-input-placeholder {
  color: #80808069;
}
.style_field__1n7Ee::placeholder {
  color: #80808069;
}
.style_checkBox-position__3Va0U {
  margin-right: 3%;
}
.style_checkBox-label__1TvXL {
  display: inline-flex;
  align-items: center;
}
.style_checkBox__3wPmh + span{
  font-size: 16px;
}
.style_privacy__AelbC {
  position: static;
  padding-left: 0;
}
.style_footer__3eSjf {
  display: flex;
  justify-content: space-between;
  margin: 60px auto 0;
  width: 100%;
}
.style_submit__2mIIH {
  box-shadow: 0 0 5px grey;
  background: #ff4dd1;
  border: none;
}
.style_footer_error__2r66h {
  display: block;
  height: 100%;
  padding: 7px 10px 0;
  color: rgb(253, 50, 50);
}
.style_disabled__2rZK0 {
  background: #f9a5e3;
  color: #252934;
  border-color: #f9a5e3;
  cursor: default;
}
.style_disabled__2rZK0:hover {
  box-shadow: 0 0 5px grey;
}
.style_error__1O7WT {
  border-color: rgb(253, 50, 50);
  box-shadow: 0 0 2px rgb(253 50 50);
}
.style_error-message__3FMUv {
  position: absolute;
  bottom: 18px;
  display: block;
  margin-top: 5px;
  border: none;
  box-shadow: none;
  color: rgb(253, 50, 50);
  font-size: .7em;
}
.style_additional_block__2Hu9U {

}
.style_button__3jdYj {
  width: 40px;
  padding: 0;
  padding: initial;
  background: transparent;
}
.style_button__3jdYj:hover {
  box-shadow: none;
  transform: rotate(360deg);
}
.style_wrap__24dcB {
	min-height: calc(100vh - 100px);
	padding: 96px 32px 24px;
}
.style_button__uc0BJ {
	background: grey;
}

.style_menuContainer__24i25 > .style_overlay__2lKIy,
.style_menuContainer__24i25.style_active__33hhV > .style_overlay__2lKIy {
	position: absolute;
	right: 0;
	height: calc(100vh - 120px);
	width: calc(100vw - 120px);
	background: #fafafa;
}

.style_menuContainer__24i25.style_active__33hhV > .style_overlay__2lKIy {
	animation: style_overlay-slide-in__3NOt- 300ms forwards 300ms;
}

@keyframes style_overlay-slide-in__3NOt- {
	from {
		width: calc(100vw - 120px);
	}
	to {
		width: 0;
	}
}

.style_menuContainer__24i25 > .style_overlay__2lKIy {
	animation: style_overlay-slide-out__ebPtT 300ms forwards;
}

@keyframes style_overlay-slide-out__ebPtT {
	from {
		left: 0;
		width: 0;
	}
	to {
		left: 0;
		width: calc(100vw - 120px);
	}
}

.style_menuContainer__24i25 {
	position: fixed;
	height: 100vh;
	width: 100vw;
	background: #202934;
	border: 65px solid #181d23;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	opacity: 0;
	z-index: -1;
}

.style_menuContainer__24i25::before,
.style_menuContainer__24i25::after {
	content: "";
	position: absolute;
	width: 100%;
	min-height: 100vh;
	z-index: -1;
}

.style_menuContainer__24i25::before {
	background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Stars.svg?sanitize=true);
}

.style_menuContainer__24i25::after {
	background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Trees.svg?sanitize=true)
		bottom repeat-x;
}

.style_menuContainer__24i25.style_deactive__3gJgj {
	animation: style_fade-out__33I_Z 600ms forwards;
}

@keyframes style_fade-out__33I_Z {
	0% {
		opacity: 1;
		z-index: 999;
	}
	50% {
		opacity: 1;
		z-index: 999;
	}
	100% {
		opacity: 0;
		z-index: -1;
	}
}

.style_menuContainer__24i25.style_active__33hhV {
	animation: style_fade-in__n2hs0 300ms forwards;
}

@keyframes style_fade-in__n2hs0 {
	from {
		opacity: 0;
		z-index: -1;
	}
	to {
		opacity: 1;
		z-index: 999;
	}
}

/***** Menu Items: Animation *****/
.style_menuContainer__24i25 ul {
	margin-left: -80px;
	opacity: 0;
	animation: style_slide-out__1QNd7 200ms forwards;
}

.style_menuContainer__24i25 ul {
	list-style-type: none !important;
	font-size: 3rem;
}

@keyframes style_slide-out__1QNd7 {
	from {
		opacity: 1;
		margin-left: 0px;
	}
	to {
		opacity: 0;
		margin-left: -80px;
	}
}

.style_menuContainer__24i25.style_active__33hhV ul {
	animation: style_slide-in__2n4UP 300ms forwards 600ms;
}

@keyframes style_slide-in__2n4UP {
	from {
		opacity: 0;
		margin-left: -80px;
	}
	to {
		opacity: 1;
		margin-left: 0;
	}
}

/***** Menu Items: Hover Animation *****/
.style_menuContainer__24i25 ul li {
	border-left: 0.2rem solid transparent;
	transition: border-left 200ms;
}

.style_menuContainer__24i25 ul li a {
	font-size: 3rem;
	padding-left: 0.5rem;
}

.style_menuContainer__24i25 ul li a::after {
	content: " »";
	font-size: 2.5rem;
	color: transparent;
	transition: color 200ms;
}

.style_menuContainer__24i25 ul li a:hover::after {
	content: " »";
	color: #f300b4;
}

.style_menuContainer__24i25 a,
.style_menuContainer__24i25 a:visited {
	color: #fafafa;
}

.style_menuContainer__24i25 a:hover,
.style_menuContainer__24i25 a:active {
	color: #f300b4;
}

@media only screen and (max-width: 649px) {
	.style_menuContainer__24i25 {
		border: none;
	}

	.style_menuContainer__24i25 > .style_overlay__2lKIy,
	.style_menuContainer__24i25.style_active__33hhV > .style_overlay__2lKIy {
		height: 100vh;
		width: 100vw;
	}

	.style_menuContainer__24i25.style_active__33hhV > .style_overlay__2lKIy {
		animation: style_overlay-slide-in__3NOt- 300ms forwards 300ms;
	}

	@keyframes style_overlay-slide-in__3NOt- {
		from {
			width: 100vw;
		}
		to {
			width: 0;
		}
	}

	.style_menu-container__2KF-0 > .style_overlay__2lKIy {
		animation: style_overlay-slide-out__ebPtT 300ms forwards;
	}

	@keyframes style_overlay-slide-out__ebPtT {
		from {
			left: 0;
			width: 0;
		}
		to {
			left: 0;
			width: 100vw;
		}
	}
}

.style_navbar__2aN7g {
	position: fixed;
	z-index: 9999;
	width: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
}

.style_navbar__2aN7g.style_bgActive__33r-p {
	background: #181d23;
}

.style_navbar__2aN7g .style_navWrapper__OGrE_ {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1400px;
	padding: 0 2rem;
}

.style_navbar__2aN7g .style_brand__2kXYZ {
	font-size: 1.6rem;
	color: #fafafa;
}

/***** Menu Button *****/
.style_controls__LY3IV {
	display: flex;
	align-items: center;
}
.style_signInButton__2uX3i {
	padding: 7px 20px;
	margin-right: 3vw;
	color: #ffffff !important;
  border: 1px solid #fff;
	font-size: 1rem;
}

.style_menuButton__1PU7Q {
	position: relative;
	width: 35px;
	height: 35px;
	border: none;
	outline: none;
	padding: 0;
	
}
.style_menuButton__1PU7Q:hover {
	box-shadow: none;
}

.style_menuButton__1PU7Q span,
.style_menuButton__1PU7Q span::before,
.style_menuButton__1PU7Q span::after {
	position: absolute;
	content: "";
	width: 30px;
	height: 3px;
	background: #fafafa;
	transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
	left: 0;
}

.style_menuButton__1PU7Q span {
	position: absolute;
	display: block;
	top: 50%;
	transform: translate(0, -50%);
}

.style_menuButton__1PU7Q span::before {
	top: -8px;
}

.style_menuButton__1PU7Q span::after {
	top: 8px;
}

.style_menuButton__1PU7Q:hover > span,
.style_menuButton__1PU7Q:hover > span::before,
.style_menuButton__1PU7Q:hover > span::after {
	background: #f300b4;
}

.style_menuButton__1PU7Q.style_active__3Rjzg > span {
	background: transparent;
}

.style_menuButton__1PU7Q.style_active__3Rjzg > span::before {
	transform: rotate(-225deg);
	top: 0px;
}

.style_menuButton__1PU7Q.style_active__3Rjzg > span::after {
	transform: rotate(225deg);
	top: 0px;
}

@media only screen and (max-width: 849px) {
	.style_navbar__2aN7g {
		background: #181d23aa;
	}
}

.style_subMenuWrap__3x-HZ {
  position: absolute;
  top: 34px;
  left: 0;
  background-color: #181d23;
  
  /* margin-top: -2px; */
  /* z-index: -1; */
}
.style_menu__2g8MC * {
  color: #ffffff !important;
  font-size: 1rem !important;
}
.style_menu__2g8MC {
  list-style: none;
  padding: 15px 3rem 15px 15px;
  padding-top: 20px;
  /* margin-top: -10px; */
  /* z-index: -1; */
}

.style_menu__2g8MC li:not(:first-child) {
  margin-top: 10px;
}
.style_logoutButton__1-qK0 {
  border: none;
  padding: 0;
}
.style_logoutButton__1-qK0:hover {
  box-shadow: none;
}
footer {
	font-size: 1rem;
	display: flex;
	justify-content: center;
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: #fafafa;
	z-index: 0;
}

.style_wrapper__2bQcZ {
	display: flex;
	width: 100%;
	padding: 2rem;
	max-width: 1400px;
	align-items: center;
	justify-content: space-between;
}

@media only screen and (max-width: 649px) {
	.style_wrapper__2bQcZ {
		flex-direction: column;
	}

	.style_wrapper__2bQcZ h3 {
		padding-bottom: 0.8rem;
	}
}

.app_wrap__1a09C {
	min-height: calc(100vh - 100px);
	padding-top: 64px;
	padding-bottom: 24px;
	margin: 0 auto;
}

