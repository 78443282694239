.wrap {
	clip-path: none;
	/* padding-top: 3rem; */
	/* padding-bottom: 0; */
}

.flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.buttonContainer {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 40px;
}

.addPokemonButton {
	background-color: #f300b4;
	border-color: #f300b4;
}

.disabled {
	background-color: #ffa7e9 !important;
	border-color: #ffa7e9 !important; 
	cursor: default;
}

.disabled:hover {
	box-shadow: none;
}

.description{
	display: block;
	text-align: center;
	margin-bottom: 20px;
}
.card {
	transition: margin-top .6s !important;
}