.root {
	background-image: url('../../../../assets/images/board.png');
	background-size: cover;
	background-repeat: no-repeat;
	height: calc(100vw / 1.71);
	position: relative;
}
.board {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	position: absolute;
	width: 50.3vw;
	height: 50.3vw;
	top: 4.3vw;
	left: 25.1vw;
}
.boardPlate {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	cursor: pointer;
}
.boardPlate:hover {
	background: rgba(255,255,255, .1);
}
.boardPlate.disabled:hover {
	background: none;
}
.card {
	max-height: 14.8vw;
	margin: 0 auto;
	margin-top: -6vw;
	width: 100%;
	position: static !important;
}
.card:first-child {
	margin-top: 0;
}

.player {
	position: absolute;
	top: 4.3vw;
	width: 15vw;
	height: 50.3vw;
}
.player.active {
	box-sizing: initial;
	border-left: 1px solid #fce756;
	border-right: 1px solid #fce756;
	padding: 15px;
	backdrop-filter: blur( 4px );
	background-color: rgb(177,118,43, .4);
	border-radius: 24px;
}
.one {
	left: 3vw;
}
.two {
	right: 3vw;
}
.chooseCard {
	margin-left: -20px;
}
.finishButton {
	margin-top: 100px;
}
